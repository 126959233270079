<div class="table-cell-status-wrapper">
        <span
          [class]="[
          'status-icon',
          status.toLowerCase().replace(' ', '-'),
          ]"></span>
<!-- FIXME Farben absprechen-->
  <jf-icon
    [name]="'circle'"
    [variant]="'fill'"
    [class]="[
            'status-icon',
            status.toLowerCase().replace(' ', '-'),
            ]"
  ></jf-icon>
  {{ status }}
</div>
