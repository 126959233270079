import {Component, inject, OnChanges, SimpleChanges} from '@angular/core';
import {BelegDTO, GeschaeftskundendatenDTO, PrivatkundendatenDTO} from '../../../../../openapi/fakturierung-openapi';
import {ELEMENT_DATA} from '../../../../components/table/table-wrapper/table/table.component';
import {DataHolder} from '../../../../components/table/table-wrapper/table/data-holder';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/states/app.state';
import {KundeEntitiesSelectors} from '../../../../../store/selectors/kunde-entities.selectors';
import {take} from 'rxjs';


@Component({
  standalone: true,
  selector: 'app-kunde.cell',
  templateUrl: './kunde.cell.component.html',
})
export class KundeCellComponent {

  protected data?: BelegDTO = (inject(ELEMENT_DATA) as DataHolder<BelegDTO>).data;
}
