import {Component, inject, OnDestroy, OnInit, Type} from '@angular/core';
import {debounceTime, of, Subject} from 'rxjs';
import {initialFakturierungsbelegTableState} from '../../../store/reducers/fakturierungsbeleg-table.reducer';
import {FakturierungsbelegTableSelectors} from '../../../store/selectors/fakturierungsbeleg-table.selectors';
import {FakturierungsbelegTableActions} from '../../../store/actions/fakturierungsbeleg-table.actions';
import {TableCells} from '../../components/table/table-wrapper/table-cells.type';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {FormFieldTextComponent, IconModule, NavigationService, RouterActions} from '@adnova/jf-ng-components';
import {switchMap, takeUntil} from 'rxjs/operators';
import {FakturierungsbelegEntitiesSelectors} from '../../../store/selectors/fakturierungsbeleg-entities.selectors';
import {StatusCellComponent} from './cells/status/status.cell.component';
import {BelegnummerCellComponent} from './cells/belegnummer/belegnummer.cell.component';
import {KundeCellComponent} from './cells/kunde/kunde.cell.component';
import {RechnungsdatumCellComponent} from './cells/rechnungsdatum/rechnungsdatum.cell.component';
import {NettobetragCellComponent} from './cells/nettobetrag/nettobetrag.cell.component';
import {BruttobetragCellComponent} from './cells/bruttobetrag/bruttobetrag.cell.component';
import {ActionsCellComponent} from './cells/actions/actions.cell.component';
import {TableSettings} from '../../../interfaces/table-setting.interface';
import {BelegeEmptyStateDialogActions} from '../../../store/actions/belege-empty-state-dialog.actions';
import {FormControl} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMultiSortModule} from 'ngx-mat-multi-sort';
import {TableRowButtonsModule} from '../../components/table/table-row-buttons/table-row-buttons.module';
import {TableWrapperModule} from '../../components/table/table-wrapper/table-wrapper.module';
import {AppNavigationModule} from '../../pages/app-navigation/app-navigation.module';
import {EmptyStateModule} from '../../components/empty-state/empty-state.module';
import {PaginationModule} from '../../components/pagination/pagination.module';
import {FilterBarModule} from '../../components/filter-bar/filter-bar.module';
import {BelegDTO, PageableDTO} from '../../../openapi/fakturierung-openapi';


/**
 * IDs der Spalten der Tabelle, die in der Column-Definition definiert sind.
 */
export enum FakturierungsbelegColumn {
  Status = 'status',
  // Faelligkeit = 'faelligkeit',
  Rechnungsnummer = 'rechnungsnummer',
  Kunde = 'kunde',
  Rechnungsdatum = 'rechnungsdatum',
  Nettobetrag = 'nettobetrag',
  Bruttobetrag = 'bruttobetrag',
  Actions = 'actions',
}

@Component({
  selector: 'app-fakturierungsbelege',
  templateUrl: './fakturierungsbelege.component.html',
  styleUrls: ['./fakturierungsbelege.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatMultiSortModule,
    IconModule,
    TableRowButtonsModule,
    TableWrapperModule,
    IconModule,
    AppNavigationModule,
    EmptyStateModule,
    PaginationModule,
    FilterBarModule,
    FormFieldTextComponent,
  ],
})
export class FakturierungsbelegeComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private _store = inject(Store<AppState>);
  private _navigationService = inject(NavigationService);

  private _count: number = 0;
  private _offset: number = 0;
  private _pageSize: number = 0;
  private _displayedItems: BelegDTO[] = [];
  private _tableSettingsFallback = initialFakturierungsbelegTableState.tableSettings;
  private _tableSettings?: TableSettings;
  private _formControl = new FormControl<string>('');

  protected emptyState: boolean = false;

  /*
   * INFO: Hier werden die Zellen der Tabelle definiert. Die TableWrapper-Component lädt dynamisch über diese Map die
   * entsprechenden Komponenten und füllt sie mit Inhalt.
   */
  protected tableCells: TableCells = new Map<string, Type<any>>([
    [FakturierungsbelegColumn.Status, StatusCellComponent],
    // [FakturierungsbelegColumn.Faelligkeit, FaelligkeitCellComponent],
    [FakturierungsbelegColumn.Rechnungsnummer, BelegnummerCellComponent],
    [FakturierungsbelegColumn.Kunde, KundeCellComponent],
    [FakturierungsbelegColumn.Rechnungsdatum, RechnungsdatumCellComponent],
    [FakturierungsbelegColumn.Bruttobetrag, BruttobetragCellComponent],
    [FakturierungsbelegColumn.Nettobetrag, NettobetragCellComponent],
    [FakturierungsbelegColumn.Actions, ActionsCellComponent],
  ]);

  ngOnInit(): void {
    this._navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(inhaber => {
        if (!inhaber) return of([]);
        return this._store.select(FakturierungsbelegEntitiesSelectors.fakturierungsbelegeByInhaberId(inhaber.id));
      })).subscribe(belege => {
      this.emptyState = belege.length === 0;
    });

    this._store.select(FakturierungsbelegTableSelectors.pageableDto).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(pageable => {
      this._offset = (pageable.number || 0) * (pageable.size || 0);
      this._pageSize = pageable.size || 0;
    });

    this._store.select(FakturierungsbelegEntitiesSelectors.count).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(count => {
      this._count = count;
    });

    this._store.select(FakturierungsbelegTableSelectors.displayedBelege).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(displayedItems => {
      this._displayedItems = displayedItems;
    });

    this._store.select(FakturierungsbelegTableSelectors.tableSettings).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(tableSettings => {
      this._tableSettings = tableSettings;
    });

    this.formControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
    ).subscribe(value => {
      if (!value) return;
      const searchValue = value.split(' ');
      this._store.dispatch(FakturierungsbelegTableActions.updateSearchValue({searchValue}));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openBelegeEmptyStateDialog(): void {
    this._store.dispatch(BelegeEmptyStateDialogActions.open());
  }

  changedPageableHandler(pageable: PageableDTO): void {
    this._store.dispatch(FakturierungsbelegTableActions.changePage({pageableDto: pageable}));
  }

  changedTableSettingsHandler(tableSettings: TableSettings): void {
    this._store.dispatch(FakturierungsbelegTableActions.changeTableSettings({tableSettings}));
  }

  get count(): number {
    return this._count;
  }

  get offset(): number {
    return this._offset;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get displayedItems(): BelegDTO[] {
    return this._displayedItems;
  }

  get tableSettingsFallback(): TableSettings {
    return this._tableSettingsFallback;
  }

  get tableSettings(): TableSettings | undefined {
    return this._tableSettings;
  }

  get formControl(): FormControl<string | null> {
    return this._formControl;
  }

  openFakturierungsbelegEdit(dto: BelegDTO) {
    this._store.dispatch(RouterActions.navigateByUrl({
      url: 'fakturierungsbelege/inhaber/' + dto.betriebId + '/edit/fakturierungsbeleg/' + dto.id,
    }));
  }
}
