import {Component, inject, OnInit} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {FakturierungsbelegEntitiesSelectors} from '../../../store/selectors/fakturierungsbeleg-entities.selectors';
import {
  DeleteFakturierungsbelegDialogSelectors
} from '../../../store/selectors/delete-fakturierungsbeleg-dialog.selectors';
import {take} from 'rxjs';
import {BelegDTO, GeschaeftskundendatenDTO, PrivatkundendatenDTO} from '../../../openapi/fakturierung-openapi';
import {DeleteFakturierungsbelegDialogActions} from '../../../store/actions/delete-fakturierungsbeleg-dialog.actions';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {KundeEntitiesSelectors} from '../../../store/selectors/kunde-entities.selectors';


@Component({
  selector: 'app-delete-fakturierungsbeleg-dialog',
  templateUrl: './delete-fakturierungsbeleg-dialog.component.html',
  styleUrls: ['./delete-fakturierungsbeleg-dialog.component.scss']
})
export class DeleteFakturierungsbelegDialogComponent implements OnInit {

  private _store = inject(Store<AppState>);
  private _logger = inject(NGXLogger);
  private _snackbar = inject(MatSnackBar);

  private _fakturierungsbelegDto?: BelegDTO;
  private _kundeName?: string;

  get fakturierungsbelegDto(): BelegDTO | undefined {
    return this._fakturierungsbelegDto;
  }

  get kundeName(): string | undefined {
    return this._kundeName;
  }

  ngOnInit() {
    this._store.select(DeleteFakturierungsbelegDialogSelectors.deleteData).pipe(
      take(1),
    ).subscribe(data => {

      if (data.fakturierungbelegId) {
        this._store.select(FakturierungsbelegEntitiesSelectors.fakturierungsbelegById(data.fakturierungbelegId)
        ).pipe(
          take(1),
        ).subscribe(fakturierungsbelegDto => {
          this._fakturierungsbelegDto = fakturierungsbelegDto;
        });
      } else {
        this._logger.warn('delete dialog fakturierungsbelegId is not set');
      }
    });
  }

  protected deleteFakturierungsbelegClick(): void {
    this._snackbar.open('"Löschen" wurde geklickt', undefined, {
      duration: 1000,
    });

    this.closeDialogClick();
  }

  protected closeDialogClick(): void {
    this._store.dispatch(DeleteFakturierungsbelegDialogActions.close());
  }
}
