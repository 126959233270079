import {Component, inject} from '@angular/core';
import {ELEMENT_DATA} from '../../../../components/table/table-wrapper/table/table.component';
import {IconModule} from '@adnova/jf-ng-components';
import {BelegDTO} from '../../../../../openapi/fakturierung-openapi';
import {DataHolder} from '../../../../components/table/table-wrapper/table/data-holder';


@Component({
  standalone: true,
  selector: 'app-status.cell',
  templateUrl: './status.cell.component.html',
  styleUrls: ['./status.cell.component.scss'],
  imports: [
    IconModule,
  ],
})
export class StatusCellComponent {

  protected data?: BelegDTO =  (inject(ELEMENT_DATA) as DataHolder<BelegDTO>).data;

  get status(): string {
    if(this.data?.status?.offen){
      return 'Offen';
    }
    if(this.data?.status?.storniert){
      return 'Storniert';
    }
    if(this.data?.status?.festgeschrieben){
      return 'Fertiggestellt';
    }
    return '';
  }
}
